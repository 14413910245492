.login {
  $self: &;

  .back-button + #{$self}__logo {
    margin-top: -50px;
  }

  &__title {
    margin-bottom: 16px;
  }

  &__description {
    margin-bottom: 18px;
  }

  &__input {
    padding-bottom: 34px;
    width: 100%;
  }

  &__logo {
    height: 340px;
    object-fit: cover;
    object-position: bottom;
    width: 100%;

    &--small {
      max-height: 170px;
    }

    &--extra-small {
      max-height: 100px;
    }
  }

  &__forgot {
    font-weight: 500;
    margin-top: -24px;
    position: relative;
    text-align: head;
  }

  &__text {
    color: $chat-checkbox-color;
  }

  &__welcome-image {
    display: block;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 12px;
    max-width: 350px;
    width: 100%;
  }

  &__submit {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5px;

    .MuiButton-contained.Mui-disabled {
      background-color: $dark-gray;
      color: $white;
    }

    &--footer {
      align-items: center;
      background-color: $white;
      bottom: 0;
      box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.08);
      display: flex;
      height: 120px;
      left: 0;
      padding-top: 10px;
      position: fixed;
      right: 0;
      justify-content: center;
      z-index: 1000;
    }

    .text-button {
      cursor: pointer;
      padding: 5px 12px 20px;
    }
  }

  &__button {
    border-radius: 100px;
    height: 48px;
    margin-bottom: 20px;
    text-transform: none;
    width: 274px;
  }

  &__text-button {
    color: black;
    font-weight: 600;
    cursor: pointer;
  }

  &__modal {
    background-color: $white;
    border-radius: 50px 50px 0 0;
    bottom: 0;
    overflow: hidden;
    position: absolute;

    &:focus {
      outline: none;
    }

    &--close {
      position: absolute;
      right: 25px;
      top: 15px;
    }
  }

  &__modal-title,
  &__welcome-title {
    margin-bottom: 16px;
  }

  &__modal-paragraph,
  &__welcome-paragraph {
    margin-bottom: 24px;
    padding-left: 4px;
    padding-right: 4px;
  }

  &__modal-logo {
    width: 100%;
  }

  &__modal-content,
  &__welcome-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  &__error-message {
    color: $error-color;
    margin-bottom: 20px;
  }

  &__terms {
    position: relative;
    padding-bottom: 160px;
  }
}

.login-desktop {
  height: 100%;

  &__grid {
    height: 100%;

    &--item {
      height: 100%;
    }

    &--scroll {
      overflow: scroll;
    }
  }

  &__logo {
    height: 100%;
    object-fit: cover;
    object-position: 100% 0;
    pointer-events: none;
    width: 100%;
  }

  &__content {
    align-items: center;
    display: flex;
    min-height: 100%;
    justify-content: center;
    overflow: hidden;
  }

  &__modal {
    background-color: $white;
    border-radius: 50px 50px 50px 50px;
    left: 0;
    right: 0;
    margin: auto;
    overflow: hidden;
    position: absolute;
    max-width: 600px;

    &:focus {
      outline: none;
    }
  }

  &__submit {
    &--footer {
      background-color: $white;
      bottom: 0;
      box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.08);
      left: 33.4%;
      padding: 30px 0 20px;
      position: fixed;
      right: 6px;
    }
  }

  &__section {
    padding-left: 160px;
    padding-right: 160px;
    width: 100%;

    &--terms {
      padding-bottom: 160px
    }
  }

  &__welcome {
    margin: auto;
    max-width: 600px;
  }

  &__welcome-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  &__modal-container {
    align-items: center;
    display: flex;
  }
}
