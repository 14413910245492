.navbar {
  background-image: url("/images/nav_background.png");
  background-repeat: no-repeat;
  background-size: cover;

  .MuiToolbar-regular {
    height: $nav-height;
  }

  &__title {
    flex-grow: 1;
    font-weight: 600;
    text-align: center;

    &--drawer {
      margin-right: -40px;
    }
  }

  &__button {
    position: absolute;
  }

  &--hide {
    display: none;
  }
}

.navbar-desktop {
  background-image: url("/images/nav_background_desktop.png");
  background-repeat: no-repeat;
  background-size: cover;

  &__logo-link {
    height: 100%;
  }

  .MuiToolbar-regular {
    height: $nav-height-desktop;
    min-height: $nav-height-desktop;
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
  }

  &__content {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    text-align: center;
    width: 100%;
  }

  &__tabs {
    margin-right: 0px;

    .MuiTabs-indicator {
      background-color: $white;
      border-radius: 20px;
      height: 8px;
      margin-left: 12px;
      max-width: 45px;
    }

    .MuiTab-root {
      min-width: 0;
    }

    .MuiTabs-scrollButtons {
      display: none;
    }

    .MuiTab-wrapper {
      align-items: flex-start;
      color: $white;
      text-transform: none;
    }

    .Mui-selected {
      color: $white;
      font-weight: 600;
    }
  }

  &__logout {
    font-weight: 600;
    margin-right: 50px;
    margin-left: 80px;
    cursor: pointer;

    .MuiTypography-colorPrimary {
      color: $white;
    }
  }

  .dropdown__submenu {
    display: none;
  }

  $submenu-width: 200px;

  .dropdown {
    display: inline-block;
    text-align: left;

    a {
      color: white;
      cursor: pointer;
      display: block;
      font-weight: 500;
      line-height: 35px;
      padding: 0 16px;
      text-decoration: none;
    }

    ul {
      list-style: none;
      margin-bottom: 0px;
      margin-top: 0px;
      padding-left: 0;
    }

    &__menu-item {
      display: inline-block;
      font-weight: 500;

      &:hover {
        .dropdown__submenu {
          display: block;
          border-radius: 4px;
        }
      }
    }

    &__menu-item-tab {
      display: flex;
    }

    &__angle {
      color: $white;
      margin-top: 15px;
      margin-right: 12px;
    }

    &__submenu {
      background-color: $white;
      border: 1px solid $light-pink;
      color: $white;
      display: none;
      font-weight: 500;
      position: absolute;
      overflow: hidden;
      text-transform: none;
      width: $submenu-width;
      z-index: 100;

      a {
        line-height: 42px;
        color: $pink;
      }
    }

    &__submenu-item:hover  {
      background: $pink;

      a {
        color: $white;
      }
    }
  }
}
