.hp-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    @extend %hide-scrollbar;
    background-color: $white;
    border-radius: 30px;
    margin: 0 40px;
    max-height: 600px;
    max-width: 550px;
    overflow-y: scroll;
    padding: 40px 0;
    position: relative;

    &:focus {
      outline: none;
    }
  }

  &__button,
  &__image {
    display: block;
    margin: auto;
    max-height: 200px;
    max-width: 200px;
    object-fit: contain;
  }

  &__paragraph {
    margin-top: 30px;
  }

  &__title {
    font-weight: 600;
    font-size: 24px;
  }

  // TODO: Need to Update the modal close button design when it exist
  &__close-button {
    border: 2px solid $black;
    cursor: pointer;
    padding: 5px;
    position: absolute;
    right: 30px;
    top: 15px;
  }

  .MuiBackdrop-root {
    background-color: rgb(0 0 0 / 75%);
  }

  &__text {
    cursor: pointer;
    font-weight: 600;
    margin-top: 16px;
    text-align: center;
  }
}
