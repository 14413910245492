.slider-card {
  border-radius: 10px;
  border: 3px solid $chat-slider-border-color;
  margin-right: 16px;
  min-width: 268px;
  overflow: initial;

  &__content {
    padding-bottom: 8px !important;
  }

  &__header {
    font-weight: 600;
    margin-bottom: 8px;
    text-align: center;
  }

  &__description {
    color: $chat-slider-color;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: -2px;
    text-align: center;
  }

  &__image {
    margin: auto;
    margin-bottom: 4px;
    max-height: 150px;
    width: 100%;
  }

  &__label {
    display: flex;
    margin-right: -13px;
    margin-top: 4px;
    justify-content: space-between;
  }

  &__label-text {
    font-size: 14px;
    opacity: 0;

    &--show {
      color: $dark-gray;
      font-size: 14px;
      font-weight: bold;
      opacity: 1;
    }

    &--selected {
      color: $chat-slider-color;
      font-weight: bold;
      font-size: 14px;
      opacity: 1;
    }
  }

  .MuiSlider-thumb {
    height: 26px;
    width: 26px;
    background-color: $white;
    border: none;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    margin-left: -13px;
  }

  .MuiSlider-mark {
    display: none;
  }
}
