.back-button {
  color: $black;
  left: 20px;
  top: 20px;

  &--absolute {
    position: absolute;
  }

  &--nav {
    color: $white;
    left: unset;
    top: unset;
  }
}
