.article-card {
  border-radius: 16px;
  box-shadow: $shadow;
  height: 84px;

  &__content {
    display: flex;
    height: 84px;
  }

  &__image {
    height: 100%;
    width: 90px;

    @media (min-width: 1200px) {
      width: 112px;
    }
  }

  &__title {
    flex-grow: 1;
  }

  &__header {
    font-size: 1rem;
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__icon {
    height: 28px;
    margin-right: 8px;
    margin-top: 46px;
    width: 28px;
  }

}
