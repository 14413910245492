@import 'variables';
@import 'chat';
@import 'global';
@import 'navbar';
@import 'main';
@import 'data-donation';
@import 'profile';
@import 'article';
@import 'login';
@import 'back-button';
@import 'article-card';
@import 'library';
@import 'slider-card';
@import 'article-list';
@import 'chat-action';
@import 'drawer';
@import 'success';
@import 'body';
@import 'text-button';
@import 'progress-card';
@import 'settings';
@import 'check-in';
@import 'hp-modal';
@import 'note';
@import 'unsubscribe';
@import 'survey-form';
@import 'terms-conditions';

html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.purple {
  color: $purple;
}

.gray {
  color: $gray;
}

#root {
  display: flex;
  flex-direction: column;
}
