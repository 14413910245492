$stepper-size: 14px;

.data-donation {
  padding-top: $nav-height;

  &__date-picker {
    margin-bottom: 30px;

    .MuiOutlinedInput-root {
      background-color: $chat-textinput-color;
      border-radius: 24px;
    }
  }

  &__top {
    margin-left: -24px;
  }

  &__banner {
    border-radius: 12px 12px 0 0;
    width: 100%;
    height: auto;
  }

  &__description {
    margin-bottom: 32px;
  }

  &__description-title {
    margin-bottom: 16px;
  }

  &--desktop {
    background-color: $desktop-chat-background;
  }

  .logo {
    width: 100%;
  }

  .next-button {
    position: absolute;
    right: 24px;

    .MuiFab-label {
      color: $white;
    }
  }

  &__subtitle,
  &__radio-group {
    padding-bottom: 24px;
  }

  &__button-group,
  &__button {
    display: block;
    font-size: 18px;
    margin: auto;
    text-transform: none;
    width: 67%;
  }

  &__links {
    align-items: center;
    background-color: $gray;
    bottom: 0;
    box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.08);
    display: flex;
    height: 120px;
    left: 0;
    padding-top: 20px;
    position: fixed;
    right: 0;
    justify-content: space-between;
    z-index: 1;

    .text-button {
      border-radius: 30px;
      cursor: pointer;

      &:nth-child(n + 2) {
        background-color: $gray;
        color: $font-color;
      }
    }

    .data-donation__button {
      border-radius: 30px;
      padding: 12px 30px;
      margin-top: 5px;
    }

    .text-link {
      margin-bottom: 18px;
      cursor: pointer;
    }

    &--desktop {
      border-radius: 0 0 12px 12px;
      margin-left: -80px;
      margin-right: -80px;
      position: initial;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 30px;
  }

  &__subtitle {
    font-weight: 600;
    padding-bottom: 30px;
  }

  &__paragraph {
    padding-bottom: 24px;
  }

  &__image {
    display: block;
    margin: 40px auto 20px;
    max-width: 350px;
  }

  &__stepper {
    background-color: $white;
    height: 88px;
    justify-content: center;

    .MuiMobileStepper-dot {
      background-color: $light-purple;
      height: $stepper-size;
      margin: 4px;
      width: $stepper-size;
    }

    .MuiMobileStepper-dotActive {
      background-color: $dark-purple;
    }
  }

  &__input-field,
  .datepicker {
    margin-bottom: 24px;
    width: 90%;
  }

  &.button-group__bottom {
    padding-bottom: 140px;
  }

  &__question {
    padding-bottom: 8px;

    strong {
      font-weight: 600;
    }
  }

  &__form {
    &--checkbox {
      padding-bottom: 30px;

      .checkbox-group {
        background-color: $chat-container-color;
        display: flex;
        flex-wrap: wrap;
      }

      .checkbox--align-left {
        text-align: left;
      }
    }

    &--select {
      width: 100%;
      padding-bottom: 30px;

      .MuiInputLabel-formControl {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .MuiInputBase-root {
        border-radius: 30px;

        .MuiSelect-select {
          border-radius: 30px;
        }
      }
    }

    &--input {
      width: 100%;
    }
  }

  .select-group {
    display: flex;

    .data-donation__form--select {
      padding-right: 10px;

      &:nth-child(2) {
        flex: 0 0 30%;
        padding-right: 0;

        fieldset {
          span {
            padding: 0;
            max-width: 2.3rem;
          }
        }
      }
    }
  }
}
