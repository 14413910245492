$border-radius: 24px;
$chatbox-left-right-margin: 56px;

.chat {

  &__container {
    display: flex;
    flex-direction: column;
    height: calc(100% - #{$nav-height});
    overflow: hidden;
    width: 100%;
    position: fixed;
  }
}

.chat-messages {
  @extend %hide-scrollbar;
  flex-grow: 1;
  overflow-y: scroll;
  padding: 0 24px 8px;
  scroll-behavior: smooth;

  &__message {
    padding-bottom: 8px;
    padding-top: 8px;

    strong {
      font-weight: 600;
    }
  }

  &__bot {
    display: flex;

    span {
      background-color: $chat-bg-color;
      border-radius: 0px $border-radius $border-radius $border-radius;
      display: inline-block;
      margin-right: $chatbox-left-right-margin;
      padding: 16px;
    }
  }

  &__user {
    display: flex;
    justify-content: flex-end;

    span {
      background-color: $chat-user-bg-color;
      border-radius: $border-radius 0px $border-radius $border-radius;
      color: $white;
      display: inline-block;
      margin-left: $chatbox-left-right-margin;
      padding: 16px;
    }
  }

  &__red {
    span {
      background-color: $chat-urgent-red-bg-color;
      color: $white;
      font-weight: 600;
    }
  }

  &__yellow {
    span {
      background-color: $chat-urgent-yellow-bg-color;
      color: #000;
      font-weight: 600;
    }
  }
}

.chat-image {
  margin: 0 24px;
  padding-top: 16px;
  text-align: center;

  &__picture {
    max-width: 264px;
    width: 100%;
  }
}

.chat__keyboard {
  background-color: $chat-keyboard-color;
  border-top: 1px solid $my-profile-border-line-color;
  bottom: 0;
  left: 0;
  margin: 0 24px 24px;
  position: relative;
  right: 0;

  @media (max-width: 959px) {
    padding: 26px 16px 16px;
    margin: 0;
  }

  .Mui-disabled {
    background-color: $dark-gray;
  }

  &--slide {
   display:contents;
  }

  &--invis {
    height: 0;
    margin-bottom: 0 !important;
    margin-top: 16px !important;
    padding: 0;

    .slider-row,
    .MuiButton-root,
    .chat-textinput__field,
    .checkbox,
    .chat-send-container,
    .chat-send-container__send {
      opacity: 0;
    }
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: $pink-filter;
}

.chat-textinput {
  display: flex;
  flex-direction: column;

  .MuiOutlinedInput-root {
    background-color: $chat-textinput-color;
    border-radius:24px;
  }

  .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: $input-border-color;
    }
  }

  &__field {
    margin-right: 18px;
    width: calc(100% - 70px);

    &--date {
      input {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }
}

.chat-checkbox,
.data-donation__form--checkbox {
  $self: &;
  background-color: $chat-container-color;

  &__row {
    @extend %hide-scrollbar;
    display: flex;
    flex-wrap: wrap;
    max-height: 28vh;
    overflow: scroll;
  }

  .checkbox {
    align-items: center;
    background-color: $white;
    border: 2px solid $chat-checkbox-border-color;
    border-radius: 14px;
    display: flex;
    font-weight: normal;
    justify-content: center;
    margin-bottom: 8px;
    margin-right: 8px;
    padding: 8px 12px;
    text-transform: none;

    &.selected {
      background-color: $chat-checkbox-color;
      border: 2px solid $chat-checkbox-color;
      color: $white;
    }
  }
}

.chat-send-container {
  align-items: center;
  display: flex;

  &__send {
    height: 48px;
    position: absolute;
    right: 24px;
    width: 48px;

    top: -24px;
    z-index: 1;

    .MuiFab-label {
      color: $white;
    }

    &.Mui-disabled {
      background-color: $dark-gray;
    }

    &:hover.Mui-disabled {
      background-color: $dark-gray;
    }
  }

  &__confirm {
    border-radius: 100px;
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    padding: 10px;
    text-transform: none;
    width: 100%;
  }

  &__bottom-line {
    position: absolute;
    width: 100%;
  }

  &__top-line {
    border-top: 1px solid $my-profile-border-line-color;
    justify-content: flex-end;
    position: relative;
    line-height: 0.1em;
    padding: 24px 24px 0;
    margin: -18px -24px 0;

    @media (max-width: 959px) {
      margin: 0 -16px;
      padding: 16px 24px 0;
    }
  }
}

.main-template-desktop__container--padding {
  .chat__keyboard {
    padding: 24px 104px 0;
    margin: 0 -80px 24px;
  }

  .chat-send-container__bottom-line {
    width: calc(100% + 208px);
    margin: 0 -104px 24px;

    .chat-send-container__send {
      right: 104px;
      z-index: 1201;
    }
  }

  .chat-send-container__top-line {
    width: 100%;
    margin: 0 -104px;
    padding-left: 104px;
    padding-right: 104px;
  }
}

.slider {

  &__title {
    margin: 0 0 10px;
  }

  &__row {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 5px;
  }

  &__card {
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0 1px 6px $chat-diary-box-shadow-color, 0 1px 4px $chat-diary-box-shadow-color;
    margin-right: 10px;
    padding: 10px;
    text-align: center;
  }

  &__cardTitle {
    margin-bottom: 10px;
  }

  &__bar {
    appearance: none;
    background-color: $chat-slider-color;
    border-radius: 20px;
    height: 25px;
    outline: none;
    width: 100%;

    &::slider-thumb {
      appearance: none;
      background: $white;
      cursor: pointer;
      height: 25px;
      width: 25px;
    }

    &::range-thumb {
      cursor: pointer;
      height: 30px;
      width: 30px;
    }
  }
}

.slider-desktop {
  &__container {
    margin-top: 16px;

    .slider-card {
      margin-right: 0;
    }
  }
}


.chat-article {

  &__row {
    padding-bottom: 8px;
  }

  &__container {
    padding-top: 16px;
  }
}

.eventprofile {

  &__container {
    border: 3px solid $chat-event-border-color;
    border-radius: 16px;
    margin-top: 16px;
    max-width: 380px;
    overflow: hidden;
  }

  &__img {
    width: 100%;
  }

  &__title {
    font-weight: 600;
  }

  &__content {
    padding: 16px;
  }

  &__header {
    font-weight: 500;
    margin-top: 16px;
  }
}

.chat-body-map {
  &__container {
    display: flex;
    justify-content: flex-end;
  }

  &__map {
    background-color: $light-pink;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    margin-top:16px;
    padding: 12px;
    position: relative;
    width: 220px;

    &--space {
      margin-right: 20px;
    }
  }
}

.chat-timestamp {
  padding-top: 56px;

  &--mobile {
    padding-top: 36px;
    text-align: center;
  }

  &__text {
    color: $light-black;
    font-weight: 500;
  }
}

.chat-desktop {
  &__container {
    background-color: $white;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  &__safari-date-picker {
    margin-right: 18px;
    width: 100%;
  }
}

.typing-indicator {
  background-color: $chat-bg-color;
  border-radius: 0px $border-radius $border-radius $border-radius;
  display: inline-block;
  margin-right: $chatbox-left-right-margin;
  margin-top: 16px;
  padding: 16px;

  span {
    background-color: $chat-typing-indicator-color;
    border-radius: 50%;
    display: block;
    float: left;
    height: 12px;
    margin-right: 4px;
    opacity: 0.4;
    width: 12px;

    @for $i from 1 through 3 {
      &:nth-of-type(#{$i}) {
        animation: 1s blink infinite ($i * .3333s);
      }
    }
  }

  @keyframes blink {
    50% {
      opacity: 1;
    }
  }
}

.chat-page {
}
