.article {
  &__picture {
    margin-top: -50px;
    width: 100%;
  }

  &__title {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__subtitle {
    color: $light-gray;
    padding-bottom: 24px;
    text-transform: uppercase;
  }

  &__video-player {
    padding-bottom: 24px;
  }

  iframe {
    max-width: 100%;
    padding-bottom: 24px;
  }
}

.favourite-button {
  background-color: $white !important;
  border-radius: 100px;
  box-shadow: $shadow;
  color: $unfavourite-heart-color;
  float: right;
  height: 50px;
  margin-top: -55px;
  width: 50px;
}

.favourite-button-favourited {
  color: $favourite-heart-color;
}

.article-desktop {
  &__picture {
    border-radius: 12px 12px 0 0;
    margin-top: -50px;
    max-height: 204px;
    object-fit: cover;
    width: 100%;
  }

  .section {
    padding-left: 68px;
    padding-right: 68px;
  }
}

.article,
.article-desktop {

  * {
    word-wrap: break-word;
  }

  img {
    max-width: 100%;
  }

  table, th, td {
    border: 1px solid $table-border-color;
    border-collapse: collapse;
    padding-left: 4px;
    padding-right: 4px;
  }

  th {
    background-color: $table-heading-color;
    color: $white;
  }

  &__video,
  &__audio {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;

    & > iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
}
