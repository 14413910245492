.my-profile {
  padding-top: 64px;

  &__title {
    padding-bottom: 14px;
  }
}

.health-background {
  &__title {
    margin-bottom: 24px;
    text-align: center;
  }

  &__picture {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
    width: 128px;
  }
}

.event-profile {
  &__title {
    margin-bottom: 24px;
    text-align: center;
  }

  &__title--edit {
    margin-bottom: 16px;
  }

  &__title-description {
    border-bottom: 0.8px solid $my-profile-border-line-color;
    margin-bottom: 24px;
    padding-bottom: 16px;
    text-align: center;
  }

  &__date {
    margin-bottom: 32px;
  }

  &__picture {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
    width: 128px;
  }

  &__question {
    padding-bottom: 16px;
  }

  &__row {
    margin-bottom: 24px;
  }

  &__button-container {
    align-items: center;
    display: flex;
    margin-bottom: 30px;
    margin-top: 40px;
    justify-content: flex-end;
  }

  &__button {
    border-radius: 100px;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    padding: 10px;
    text-transform: none;
    width: 100%;
  }

  &__other {
    width: 100%;

    .MuiOutlinedInput-root {
      border-radius: 20px;
    }
  }

  &__body-map-container {
    border: 2px solid $my-profile-body-map-border-color;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    margin-top: 30px;
    padding-bottom: 24px;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 38px;
    position: relative;
  }

  &__body-map {
    background-color: $light-pink;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    margin-top: 16px;
    padding: 12px;
    position: relative;
    width: 140px;

    svg {
      height: 160px;
    }

    &--space {
      margin-right: 8px;
    }
  }

  &__body-map-label {
    bottom: 8px;
    color: $green;
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    right: 8px;
    z-index: 1;
  }

  &__body-map-direction-l {
    color: $green;
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    left: 34px;
    top: 34px;
    z-index: 1;
  }

  &__body-map-direction-r {
    color: $green;
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    right: 34px;
    top: 34px;
    z-index: 1;
  }

  &__fab {
    margin-right: 16px;
    margin-top: -66px;
    position: absolute;
    right: 0;

    .MuiFab-primary {
      background-color: $white;

      &:hover {
        background-color: $white;
      }

      svg {
        color: $green;
      }
    }
  }
}
