$check-in-icon-size: 30px;

.check-in {
  margin-top: $nav-height;

  &__top {
    margin-left: -24px;
    margin-top: 24px;
  }

  &__container {
    @extend %hide-scrollbar;
    overflow-y: scroll;
    padding: 24px;
  }

  &__tabs {
    background-color: $white;
    margin-left: -8px;
    padding-bottom: 24px;

    .MuiTabs-indicator {
      background-color: $neon-green;
      border-radius: 20px;
      height: 8px;
      margin-left: 12px;
      max-width: 45px;
    }

    .MuiTabs-scrollButtons {
      display: none;
    }

    .MuiTab-wrapper {
      align-items: flex-start;
      text-transform: none;
    }

    .Mui-selected {
      color: $black;
    }
  }

  &__date {
    color: $light-black;
    font-size: 14px;
    margin-bottom: 8px;
  }

  &__fab {
    margin-right: 16px;
    margin-top: -16px;
    position: absolute;
    right: 0;

    .MuiFab-primary {
      background-color: $white;
      margin-right: 8px;

      &:hover {
        background-color: $white;
      }

      svg {
        color: $green;
      }
    }
  }
}

.check-in-card {
  border-radius: 16px;
  box-shadow: $shadow;
  margin-bottom: 16px;

  &--desktop {
    max-width: 886px;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__row {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 112px;
  }

  &__title {
    flex-grow: 1;
  }

  &__header {
    font-size: 1rem;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &--desktop {
      margin-right: 140px;
      -webkit-line-clamp: 1;
    }
  }

  &__text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &--desktop {
      margin-right: 140px;
      -webkit-line-clamp: 1;
    }
  }

  &__icon {
    height: $check-in-icon-size;
    margin-left: 24px;
    margin-top: 18px;
    width: $check-in-icon-size;
  }

  .MuiCardContent-root:last-child {
    padding-left: 8px;
    padding-bottom: 16px;
  }
}

.check-in-card-entry {

  &__icon {
    height: $check-in-icon-size;
    margin-left: 8px;
    margin-top: 23px;
    width: $check-in-icon-size;
  }

  &__row {
    display: flex;
    width: 100%;
  }

  &__header {
    font-weight: 600;
    margin-bottom: 8px;
  }

  &__description {
    font-weight: 500;
    margin-bottom: 16px;
  }

  &__content {
    font-weight: normal;
  }

  &__sub-content {
    font-weight: normal;
    margin-top: 20px;
  }

  &__value {
    color: $chat-slider-color;
    font-weight: 600;
  }
}

.check-in-note-fab {
  bottom: 0;
  margin-bottom: 24px;
  margin-right: 24px;
  position: fixed;
  right: 0;

  .MuiFab-primary {
    background-color: $white;
    margin-right: 8px;

    &:hover {
      background-color: $white;
    }

    svg {
      color: $green;
    }
  }
}
