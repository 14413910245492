.text-button {
  border-radius: 30px;
  padding: 12px 30px;

  .MuiButton-label {
    font-size: 18px;
    text-align: center;
    text-transform: none;
  }
}
