.chat-action {
  pointer-events: none;
  z-index: 1200;

  &--mobile {
    left: 32px;
    position: fixed;
    top: 38px;
  }

  &--desktop {
    margin-left: -32px;
    margin-top: -32px;
    position: absolute;
  }

  .MuiFab-label {
    width: auto;
  }

  .MuiFab-primary {
    background-color: $white;

    &:hover {
      background-color: $white;
    }
    
    svg {
      color: $pink;
    }
  }
  button {
    background-color: $pink;

    &:hover {
      background-color: $pink; 
    }

    svg {
      color: $white;
    }
  }

  .MuiSpeedDialAction-staticTooltipLabel {
    background-color: $white;
    border-radius: 12px;
    color: $black;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    margin-top: -8px;
    white-space: nowrap;
  }
}