.center {
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.bottom {
  bottom: 0;
}

.section {
  padding: 24px;

  &__about {
    .back-button {
      position: absolute;
      color: $white;
    }
  }
}

.white-text {
  color: $white;
}

.black-text {
  color: $black;
}

.red-text {
  color: $red;
}

.green-text {
  color: $green;
}

.semi-bold {
  font-weight: 600;
}

.full-width {
  width: 100%;
}

.text-center {
  text-align: center;
}

.list-view {
  &__list {
    margin-bottom: 38px;
  }
}

.responsive-image {
  height: 100%;
  width: auto;
}

.pointer {
  cursor: pointer;
}

// @ref https://www.w3schools.com/howto/howto_css_hide_scrollbars.asp
%hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}
