.progress-card {
  border-radius: 16px;
  box-shadow: $shadow;
  height: 84px;
  margin-bottom: 8px;

  &__content {
    display: flex;
    height: 84px;
  }

  &__image {
    height: 100%;
    object-position: center;
    width: 100px;
  }

  &__indicator {
    align-items: center;
    display: flex;
  }

  &__title {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 75px;
    justify-content: space-around;

    .MuiLinearProgress-determinate {
      background-color: $gray;
      border-radius: 5px;
      flex-grow: 1;
      height: 7px;

      .MuiLinearProgress-barColorSecondary {
        background-color: $light-green;
      }

      .MuiLinearProgress-bar {
        border-radius: 5px;
      }
    }
  }

  &__header {
    font-size: 1rem;
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__icon {
    height: 24px;
    right: 7px;
    bottom: 5px;
    width: 24px;
  }
}
