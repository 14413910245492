.unsubscribe {
  margin-top: $nav-height;

  &__row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  &__label {
    align-self: center;
  }

  &__button {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 32px;
  }
}
