.drawer {
  // Seems not need this anymore, not sure any more function in there
  &__menu {
    align-items: center;
    background-color: $pink;
    display: flex;
    height: 140px;
    justify-content: center;
    text-align: center;
  }

  &__list {
    width: 100%;
  }

  &__text {
    margin-left: 15px;
  }

  &__avatar {
    min-height: 140px;
    object-fit: cover;
    width: 100%;
  }

  &__close-button {
    position: absolute;
    right: 16px;
    top: 16px;

    &--icon {
      max-width: 100%;
      max-height: 100%;
      text-align: center;
    }
  }

  &__icon {
    font-size: 18px;

    &--image {
      text-align: center;
    }
  }

  .MuiListItem-button {
    &:hover {
      background-color: initial;
    }
  }
}
