.main-template-desktop {
  .settings__title {
    text-align: center;
  }
}

.settings {
  margin-top: $nav-height;

  &__title {
    margin-bottom: 16px;
  }

  &__switch-row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  &__switch-label {
    align-self: center;
  }

  &__link {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-bottom: 32px;
  }

  &__link-text {
    flex-grow: 1;
  }

  &__input {
    padding-bottom: 26px;
    width: 100%;
  }

  &__button-container {
    border-top: 1px solid $my-profile-border-line-color;
    margin-top: 28px;
    padding: 28px 56px 0;
  }

  &__button {
    border-radius: 100px;
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    padding: 10px;
    text-transform: none;
    width: 100%;
  }

  &__error-message {
    color: $error-color;
    text-align: center;
  }

  .MuiSwitch-colorSecondary.Mui-checked {
    color: $white;
  }

  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: $light-green;
    opacity: 1;
  }
}
