.success-template{
  height: 100%;

  &__image {
    margin-top: 22px;
    max-width: 500px;
  }

  &__title {
    margin-bottom: 28px;
    margin-top: 38px;
  }

  &__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    text-align: center;
  }

  &__paragraph {
    margin: auto;
    
    &--mobile {
      max-width: 300px;
    }
  }

  &__button-container {
    border-top: 1px solid $my-profile-border-line-color;
    padding-top: 24px;
    width: 100%;
  }

  &__button {
    border-radius: 100px;
    height: 48px;
    margin-bottom: 20px;
    text-transform: none;
  }
}