.terms-conditions{
  h5 {
    font-size: 18px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0;
    margin-bottom: 16px;
    margin-top: 16px;
  }

  p,
  li {
    color: #212121;
    font-size: 16px;
    line-height: 26px;
  }

  p {
    margin-bottom: 30px;
    margin-top: 0;
  }

  li {
    margin-bottom: 15px;
  }
}