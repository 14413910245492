.library {
  $self: &;

  &__search {
    width: 100%;

    .MuiOutlinedInput-root {
      border-radius: 20px;
    }

    &--desktop {
      max-width: 640px;
    }
  }

  &__tab-panel {
    & > .MuiBox-root {

      @media (max-width: 959px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &__tabs {
    background-color: $white;

    .MuiTabs-indicator {
      background-color: $neon-green;
      border-radius: 20px;
      height: 8px;
      margin-left: 12px;
      max-width: 45px;
    }

    .MuiTabs-scrollButtons {
      display: none;
    }

    .MuiTab-wrapper {
      align-items: flex-start;
      text-transform: none;
    }

    .Mui-selected {
      color: $black;
    }
  }

  .article-card {
    margin-bottom: 8px;
  }

  &__cancel-button {
    font-size: 16px;
    font-weight: 600;
    color: black;
    margin-left: 15px;
    cursor: pointer;
  }

  &__cover {
    max-width: 220px;
    height: auto;
    margin: 20px 0;
  }

  &__no-faves-content {
    margin-top: 20px;
    line-height: 26px;
  }

  &__no-faves-tab {
    text-align: center;
  }

  &__loading-circle {
    padding: 100px;
  }

  &__hidden {
    display: none;
  }

  &__result-title {
    margin-bottom: 20px;
    margin-top: 15px;
  }

  &__activity-research {
    #{ $self }__tabs {
      display: none;
    }

    #{ $self }__search {
      width: calc(100% - 92px);
    }
  }
}
