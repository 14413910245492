$margin-top-height: 60px;
$margin-bottom-height: 20px;

.main-template {
  margin-top: $nav-height;
}

.main-template-desktop{
  background-color: $desktop-chat-background;
  margin-top: $nav-height;
  padding-left: 24px;
  padding-right: 24px;

  @media print {
    padding: 0;
  }

  &--fixed {
    height: calc(100% - #{$nav-height});
  }

  &__container {
    background-color: $white;
    border-radius: 12px;
    height: calc(100% - #{$nav-height} - 10px);
    margin-left: auto;
    margin-right: auto;
    margin-top: $margin-top-height;
    margin-bottom: $margin-bottom-height;
    max-width: 780px;
    min-height: calc(100vh - #{$nav-height} - #{$margin-top-height} - #{$margin-bottom-height});
    position: relative;

    @media print {
      margin: 0;
      max-width: 100%;
    }

    &--padding {
      padding-left: 80px;
      padding-right: 80px;

      @media print {
        padding: 0;
      }
    }

    &--wide {
      max-width: 1134px;

      @media print {
        max-width: 100%;
      }
    }
  }
}

.main-template--hide-navbar {
  margin-top: 0;
}

