.note {
  margin-top: $nav-height;

  &__fab {
    margin-right: 16px;
    margin-top: 24px;
    position: absolute;
    right: 0;

    .MuiFab-primary {
      background-color: $white;
      margin-right: 8px;

      &:hover {
        background-color: $white;
      }
      
      svg {
        color: $green;
      }
    }
  }

  &__date {
    color: $light-black;
    font-size: 14px;
    margin-bottom: 40px;
  }

  &__input-title {
    width: 100%;

    .MuiInputBase-root.Mui-disabled {
      color: $black;
    }

    textarea {
      font-size: 24px;
      font-weight: 600;
      line-height: 1.5;
    }
  }

  &__input-content {
    width: 100%;

    .MuiInputBase-root.Mui-disabled {
      color: $black;
    }

    textarea {
      line-height: 1.5;
    }
  }

}