.survey-form {

  &__picture {
    margin-top: -50px;
    max-height: 230px;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }

  &__links {
    align-items: center;
    border-top: 2px $survey-form-border-color solid;
    bottom: 0;
    box-shadow: none;
    display: flex;
    height: 120px;
    left: 0;
    margin-left: -24px;
    margin-right: -24px;
    padding-top: 20px;
    right: 0;
    justify-content: space-between;
    z-index: 1;

    .text-button {
      border-radius: 30px;

      &:nth-child(n+2) {
        background-color: $white;
        color: $font-color;
      }
    }

    .data-donation__button {
      border-radius: 30px;
      padding: 12px 30px;
    }

    &--desktop {
      border-radius: 0 0 12px 12px;
      margin-left: -40px;
      margin-right: -40px;
    }

    &--mobile {
      background-color: $gray;
      padding-top: 0;
      position: fixed;
    }
  }

  &__section {
    @media (max-width: 959px) {
      margin-bottom: 120px;
    }
  }
}

.survey-form-desktop {
  &__picture {
    border-radius: 12px 12px 0 0 ;
    margin-top: -50px;
    max-height: 204px;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }

  .section {
    padding-left: 40px;
    padding-right: 40px;
  }
}
