.body-map {
  stroke: $white;
  stroke-width: 2.5px;

  &--disabled {
    pointer-events: none;
    stroke: $white;
    stroke-width: 2.5px;
  }

  &--back-disabled {
    pointer-events: none;
  }

  &--unselected {
    fill: #73cedf;
  }

  &--selected-from {
    fill: #c1d830;
  }

  &--selected-to {
    fill: $pink;
  }

  &--back,
  &--back-disabled {
    stroke: $white;
    stroke-width: 2.5px;

    .body-map--unselected {
      fill:#50a2ac;
    }
  }
}

.body-modal {

  &__back-button {
    padding: 0;
  }

  &__content {
    background-color: $white;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding:16px;
  }

  &__text {
    p {
      text-align: center;
    }
  }

  &__body-map {
    background-color: $light-pink;
    display: flex;
    justify-content: center;
    padding: 14px;
    position: relative;
  }

  &__map-label {
    color: $green;
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    right: 18px;
    top: 16px;
    z-index: 1;
  }

  &__map-direction-l {
    color: $green;
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    left: 60px;
    top: 52px;
    z-index: 1;
  }

  &__map-direction-r {
    color: $green;
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    right: 60px;
    top: 52px;
    z-index: 1;
  }

  &__flip-fab {
    background-color: $white;
    bottom: 16px;
    color: $green;
    position: absolute;
    right: 18px;
  }

  &__zoom-in-fab {
    background-color: $white;
    bottom: 62px;
    color: $green;
    position: absolute;
    left: 18px;
  }

  &__zoom-out-fab {
    background-color: $white;
    bottom: 16px;
    color: $green;
    position: absolute;
    left: 18px;
  }

  &__keyboard {
    margin-top: 32px;
    position: relative;

    .chat-send-container__confirm{
      background-color: $pink;

    }

    .Mui-disabled {
      background-color: $light-gray;
    }
  }
}

.body-dialog-desktop {

  &__container {
    padding-bottom: 24px;
    padding-left: 94px;
    padding-right: 94px;
    padding-top: 24px;
  }

  .MuiDialog-paper {
    border-radius: 12px;
  }

}
