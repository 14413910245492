.article-list {
  transition: max-height .2s ease-in,
              opacity .5s;

  &__heading {
    display: flex;
    justify-content: space-between;

    .material-icons {
      transform: rotate(0);
      transition: transform .4s;
    }

    &.collapse {
      .material-icons {
        transform: rotate(180deg);
      }
    }
  }

  &--first {
    h4 {
      font-weight: 600;
      margin-top: 16px;
    }
  }

  &--second {
    h4 {
      font-weight: 500;
      margin-top: 0;
    }
  }

  &__description {
    @media (min-width: 960px) {
      width: 90%;
    }
  }

  &.collapse {
    opacity: 0;
    max-height: 0;
  }

  &__grid {
    margin-bottom: 24px;
    margin-top: 14px;

    @media (min-width: 960px) {
      padding-right: 24px;
    }

    &.collapse {
      opacity: 0;
      max-height: 0;
    }
  }
}
