$font-color: #393939;
$chat-bg-color: #f1f1f1;
$chat-user-bg-color: #015e70;
$chat-urgent-red-bg-color: #ff4545;
$chat-urgent-yellow-bg-color: #fce205; //TODO: lack design for this
$chat-typing-indicator-color: #9e9ea1;
$white: #fff;
$chat-container-color: #fff;
$chat-keyboard-color: #fff;
$chat-checkbox-border-color: #f1f1f1;
$chat-checkbox-color: #015e70;
$chat-event-border-color: #f1f1f1;
$chat-small-color: #3392a5;
$chat-small-selected-color: #123b3c;
$chat-send-color: #c2a9c1;
$chat-shadow-color: #000;
$chat-textinput-color: #f7f8f9;
$chat-confirm-color: #3393a4;
$chat-diary-color: #f0f0f0;
$chat-diary-box-shadow-color: rgba(0, 0, 0, 0.117647);
$chat-slider-color: #3393a4;
$chat-slider-border-color: #f1f1f1;
$eventprofile-color: #090909;
$eventprofile-desc-color: #838383;
$chat-title-font-size: 16px;
$chat-desc-font-size: 14px;
$gray: #f9f9f9;
$purple: #c2a9c0;
$dark-purple: #bc9fb7;
$light-purple: #d6c5d3;
$black: #000;
$light-gray: #c4c4c4;
$dark-gray: #bcbec0;
$red: #ff5678;
$neon-green: #b6d43e;
$pink: #ec008c;
$pink-filter: invert(10%) sepia(97%) saturate(7496%) hue-rotate(318deg) brightness(96%) contrast(101%);
$light-pink: #f3deec;
$green: #015e70;
$error-color: #ff0000;
$success-color: #4bb543;
$light-green: #c1d930;
$light-black: #555;
$desktop-chat-background: #f4f4f4;
$survey-form-border-color: #f1f1f1;
$my-profile-border-line-color: #e5e5e5;
$my-profile-body-map-border-color: #f1f1f1;
$unfavourite-heart-color: rgba(206, 199, 199, 0.938);
$favourite-heart-color: rgb(235, 60, 95);
$table-heading-color: #9b2f79;
$table-border-color: #ae9b95;
$input-border-color: #bebfbf;

$shadow: 2px 4px 20px rgba(0, 0, 0, 0.1);

$nav-height: 70px;
$nav-height-desktop: 48px;
